// src/components/plots/Heatmap.tsx
import React from "react";
import { HeatMapGrid } from "react-grid-heatmap";
import { convertNumberToOperatorAndNumber } from "../../utils/convertNumberToOperatorAndNumber";

interface HeatmapProps {
  data: number[][];
  formattedXLabels: string[];
  observationNames: string[];
  loincNames: string[];
  metadataClean: any;
  referenceData: any;
  normalizedData: any;
  selectedObservation: number;
  handleselectedObservationChange?: (x: number, loincID: string) => () => void;
}

const Heatmap: React.FC<HeatmapProps> = ({
  data,
  formattedXLabels,
  observationNames,
  loincNames,
  metadataClean,
  referenceData,
  normalizedData,
  selectedObservation,
  handleselectedObservationChange,
}) => {
  const formatValue = (value: number | null | undefined): string | null => {
    if (!value || value === null) {
      return null; // Return null for null or undefined values
    }

    // count the number of digits after comma, and place that t
    return value.toString();
  };
  // get length of data
  const dataLength = Object.keys(data).length;

  // cellHeight larger if data length is 1
  const cellHeight = dataLength === 1 ? "3.5rem" : "2.5rem";
  const smallFontSize = 0.3;
  const largeFontSize = 0.5;
  const fontSize = window.innerWidth < 800 ? smallFontSize : largeFontSize;

  const handleClick = (x: number, loincID: string) => {
    if (handleselectedObservationChange) {
      handleselectedObservationChange(x, loincID)();
    }
  };

  return (
    <HeatMapGrid
      // key={sortednormalizedData}
      data={data}
      xLabels={formattedXLabels}
      yLabels={observationNames}
      cellRender={(x, y, value) => {
        // get loincID
        const loincID = loincNames[x];
        const referenceRanges = referenceData[loincID];

        // get metadata for test
        const metadata = metadataClean[loincID];

        // construct the on hover // tooltip text for each cell
        // add test name, date, and value

        let hoverText = null;
        const date = formattedXLabels[y];

        if (value !== null) {
          hoverText = `Test: ${metadata.name}\nDate: ${date}\nValue: ${value}`;
          if (referenceData[loincID].min !== null) {
            hoverText += `\nReference Min: ${referenceData[loincID].min}`;
          }
          if (referenceData[loincID].max !== null) {
            hoverText += `\nReference Max: ${referenceData[loincID].max}`;
          }
        }

        // if type metadata.type is string, return value
        if (metadata.type === "string") {
          // if value matches reference text, set color to green, else set color to red

          const color =
            value === null
              ? "#CCCCCC" // default color
              : value === referenceData[loincID].text
                ? "#b2d34a"
                : "#830000";

          return (
            <div
              title={hoverText ?? undefined}
              style={{
                backgroundColor: color, // default color
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                fontSize:
                  dataLength === 1
                    ? `${fontSize + 0.2}rem`
                    : `${fontSize + 0.5}rem`, // Adjust font size based on data length
                whiteSpace: "nowrap", // Reduce gap between spaces
              }}
            >
              {value && value.toString().length > 4 && dataLength > 1 ? (
                <div>{value?.toString().substring(0, 4)}...</div>
              ) : (
                value
              )}
            </div>
          );
        }

        if (!normalizedData[loincID]) {
          return (
            <div
              title={hoverText ?? undefined}
              style={{
                backgroundColor: "white", // default color
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {value}
            </div>
          );
        }

        // get element y

        let absValue = formatValue(
          Object.values(normalizedData[loincID])[y] as number | null,
        );

        // if absValue is not null, run Math.abs
        if (absValue !== null) {
          absValue = Math.abs(Number(absValue)).toString();
        }
        let color;

        // color pallete from https://mycolor.space/?hex=%23F15B40&sub=1
        if (absValue === null && value === null) {
          // color grey if both value and absValue are null
          color = "#CCCCCC";
        } else if (absValue === null && value !== null) {
          // color red if value is not null but absValue is null
          color = "rgba(31, 109, 163, 0.45)";
        } else if (Number(absValue) >= 150) {
          color = "#830000";
        } else if (Number(absValue) >= 140) {
          color = "#a71009";
        } else if (Number(absValue) >= 130) {
          color = "#cc3a25";
        } else if (Number(absValue) >= 120) {
          color = "#f15b40";
        } else if (Number(absValue) >= 110) {
          color = "#ffc20e";
        } else if (Number(absValue) >= 100) {
          color = "#ffc20e";
        } else {
          color = "#b2d34a"; // default color
        }

        // convert value to operator and number
        const { operator, number } =
          value !== null
            ? convertNumberToOperatorAndNumber(value.toString())
            : { operator: null, number: null };

        // if operator is <, check if its below ref range min, if yes, return green
        if (operator && operator === "<" && number) {
          // if referenceRanges.min is null, then we assume test is good as its below ref range min
          const numberCorrected = number - 0.000000000001;
          if (referenceRanges.min === null) {
            color = "#b2d34a";
          } else if (numberCorrected < referenceRanges.min) {
            color = "#b2d34a";
          } else {
            color = "#830000";
          }
        }
        return (
          <div
            title={hoverText ?? undefined}
            style={{
              backgroundColor: color,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {
              // to do float needs to be adjusted based on each test (TSH vs. cholesterol
              formatValue(value)
            }
          </div>
        );
      }}
      yLabelsStyle={(index) => ({
        fontSize: `${fontSize + 0.5}rem`,
        color: index === selectedObservation ? "#1E3296" : "#999",
        fontWeight: index === selectedObservation ? "bold" : "normal",
        whiteSpace: "nowrap",
      })}
      cellStyle={() => ({
        fontSize: `${fontSize + 0.4}rem`,
        color: `white`,
        fontWeight: "bold",
      })}
      xLabelsStyle={() => ({
        // color: index % 2 ? "transparent" : "#777",
        fontSize: `${fontSize + 0.5}rem`,
      })}
      cellHeight={cellHeight}
      xLabelsPos="bottom"
      onClick={(x) => {
        const loincID = loincNames[x];
        handleClick(x, loincID);
      }}
      yLabelsPos="left"
      // square
    />
  );
};

export default Heatmap;
