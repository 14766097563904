import React from "react";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export interface CardElement {
  title: string;
  description: string;
  uri: string;
}

function CustomCards({ elements }: { elements: CardElement[] }) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        // TODO understand why 100% will overflow screen width
        width: "80%",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(min(250px, 100%), 1fr))",
        // set gap by screen size
        gap: { xs: 2, sm: 2, md: 3, lg: 4 },
        // set margin by screen size
        margin: { xs: 2, sm: 4, md: 12 },
      }}
    >
      {elements.map((card, index) => (
        <Card key={index}>
          <CardActionArea
            onClick={() => {
              navigate(card.uri);
            }}
            sx={{
              height: "100%",
              "&[data-active]": {
                backgroundColor: "action.selected",
                "&:hover": {
                  backgroundColor: "action.selectedHover",
                },
              },
            }}
          >
            <CardContent sx={{ height: "100%" }}>
              <Typography variant="h5" component="div">
                {card.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {card.description}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </Box>
  );
}

export default CustomCards;
