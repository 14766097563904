import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";

const ReferralPage: React.FC = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const [email, setEmail] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    service_provider_email: "",
    service_provider_name: "",
    service_provider_phone: "",
    service_provider_website: "",
    message: "",
    type: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const activeAccount = instance.getActiveAccount();

  useEffect(() => {
    if (activeAccount && activeAccount.username) {
      setEmail(activeAccount.username);
    } else {
      setEmail(null);
    }
  }, [activeAccount]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const hubspotutk = document.cookie
    .split("; ")
    .find((row) => row.startsWith("hubspotutk="))
    ?.split("=")[1];

  const handleFormSubmit = async () => {
    if (!formData.type) {
      alert(t("b2c.referralPage.customerTypeRequired"));
      return;
    }

    if (
      !formData.service_provider_email &&
      !formData.service_provider_phone &&
      !formData.service_provider_website
    ) {
      alert(t("b2c.referralPage.serviceProviderRequired"));
      return;
    }

    if (!formData.message) {
      alert(t("b2c.referralPage.messageRequired"));
      return;
    }

    setIsSubmitting(true);
    setSubmitSuccess(false);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      fields: [
        {
          objectTypeId: "0-1",
          name: "email",
          value: formData.service_provider_email,
        },
        {
          objectTypeId: "0-1",
          name: "name",
          value: formData.service_provider_name,
        },
        {
          objectTypeId: "0-1",
          name: "phone",
          value: formData.service_provider_phone,
        },
        {
          objectTypeId: "0-1",
          name: "website",
          value: formData.service_provider_website,
        },
        {
          objectTypeId: "0-1",
          name: "message",
          value: formData.message,
        },
        {
          objectTypeId: "0-1",
          name: "contact_referred_by",
          value: email,
        },
        {
          objectTypeId: "0-1",
          name: "customer_type",
          value: formData.type,
        },
      ],
      context: {
        hutk: hubspotutk, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        pageUri: "https://www.simplecheck.com.br/referral",
        pageName: "Referral Page",
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: "By submitting this form, you agree to Simplecheck contact the service provider and coordinate the transfer of patient health information to our secure servers, and notify you about the status of the transfer of health information to our platform.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: "I agree to receive marketing communications from Example Company.",
            },
          ],
        },
      },
    });

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow" as RequestRedirect,
    };

    fetch(
      "https://api.hsforms.com/submissions/v3/integration/submit/48595870/9e613c7f-16a7-4674-82e0-aadbaea8ebe0",
      requestOptions,
    )
      .then((response) => response.text())
      .then(() => {
        setSubmitSuccess(true);
        setFormData({
          service_provider_email: "",
          service_provider_name: "",
          service_provider_phone: "",
          service_provider_website: "",
          message: "",
          type: "",
        });
      })
      .catch((error) => console.error(error))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          {t("b2c.referralPage.title")}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t("b2c.referralPage.description")}
        </Typography>
        <Grid
          container
          spacing={4}
          alignItems="center"
          marginTop={{ xs: 2, sm: 2, md: 3 }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            <Box
              component="img"
              sx={{
                width: "100%",
                maxWidth: { xs: "100%", md: "500px" },
                height: "auto",
              }}
              alt="Referral"
              src="/images/hospital.png"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box mt={4}>
              <Typography variant="h6" component="div" fontWeight="bold">
                {t("b2c.referralPage.contact_form_title")}
              </Typography>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="demo-simple-select-label">
                  {t("b2c.referralPage.type")}
                </InputLabel>
                <Select
                  onChange={handleSelectChange}
                  id="demo-simple-select"
                  value={formData.type}
                  label={t("b2c.referralPage.type")}
                  name="type"
                >
                  <MenuItem value="Laboratory">
                    {t("b2c.referralPage.type.lab")}
                  </MenuItem>
                  <MenuItem value="Hospital">
                    {t("b2c.referralPage.type.hospital")}
                  </MenuItem>
                  <MenuItem value="Clinician">
                    {t("b2c.referralPage.type.doctor")}
                  </MenuItem>
                  <MenuItem value="Pharmacy">
                    {t("b2c.referralPage.type.pharmacy")}
                  </MenuItem>
                  <MenuItem value="Service Provider">
                    {t("b2c.referralPage.type.other")}
                  </MenuItem>
                </Select>
                <TextField
                  fullWidth
                  margin="normal"
                  label={t("b2c.referralPage.service_provider_email")}
                  name="service_provider_email"
                  value={formData.service_provider_email}
                  onChange={handleInputChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label={t("b2c.referralPage.service_provider_phone")}
                  name="service_provider_phone"
                  value={formData.service_provider_phone}
                  onChange={handleInputChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label={t("b2c.referralPage.service_provider_website")}
                  name="service_provider_website"
                  value={formData.service_provider_website}
                  onChange={handleInputChange}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label={t("b2c.referralPage.message")}
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  multiline
                  minRows={3}
                />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mt: 2 }}
                >
                  {t("b2c.referralPage.consent_text")}
                </Typography>
                {submitSuccess ? (
                  <Alert severity="success" sx={{ mt: 2 }}>
                    {t("b2c.referralPage.thank_you_message")}
                  </Alert>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleFormSubmit}
                    sx={{ mt: 2 }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      t("b2c.referralPage.submit_form")
                    )}
                  </Button>
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ReferralPage;
