import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

interface CustomDialogProps {
  open: boolean;
  title: string;
  description: string;
  cancel: string;
  confirm: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const CustomDialog: React.FC<CustomDialogProps> = ({
  open,
  title,
  description,
  cancel,
  confirm,
  onConfirm,
  onCancel,
}) => {
  const handleConfirm = () => {
    onConfirm();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="custom-dialog-title"
      aria-describedby="custom-dialog-description"
    >
      <DialogTitle id="custom-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="custom-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {cancel}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
