import React, { useEffect, useState } from "react";
import En from "../../images/flags/en.svg";
import Pt from "../../images/flags/pt.svg";

const flagMap: { [key: string]: string } = {
  // flag icon from https://nucleoapp.com/svg-flag-icons
  en: En,
  pt: Pt,
};

const FlagIcon: React.FC<{ language: string }> = ({ language }) => {
  const [flagSrc, setFlagSrc] = useState<string>(flagMap.en);

  useEffect(() => {
    const normalizedLanguage = language.split("-")[0];
    setFlagSrc(flagMap[normalizedLanguage] || flagMap.en);
  }, [language]);

  return (
    <img
      src={flagSrc}
      alt={`${language} flag`}
      style={{ width: "90%", height: "100%" }}
    />
  );
};

export default FlagIcon;
