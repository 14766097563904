import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Outlet, useNavigate } from "react-router-dom";
import "./Header.css";
import { ReactComponent as Logo } from "../../images/logo-white.svg";
import SignInSignOutButton from "./SignInSignOutButton";
import WelcomeName from "./WelcomeName";
import CountryDropdown from "./CountryDropdown";
import { Typography } from "@mui/material";
import { appVersion } from "../../config/version";

function ResponsiveAppBar() {
  const navigate = useNavigate();

  return (
    // TODO understand why margins are not working (class header)
    <>
      <AppBar position="static" className="header" elevation={0}>
        {/* <Container > */}
        <Toolbar disableGutters>
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              marginLeft: "1%",
              "& svg": {
                width: { xs: "130px", sm: "150px", md: "200px" }, // Adjust the width for mobile and larger screens
                height: "auto",
              },
            }}
            onClick={() => navigate("/")}
          >
            <Logo />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              maxWidth: "100%",
              // mr: "1%",
              // ml: "3%",
              // add white letter style always
              ".MuiFilledInput-root": {
                backgroundColor: "#FFFF", // change background color to white
              },

              // add white style background when element is hovered
              ".MuiFilledInput-root:hover": {
                backgroundColor: "#FFFF", // change background color to white
              },

              // add white style background when element is focused
              ".MuiFilledInput-root.Mui-focused": {
                backgroundColor: "#FFFF", // change background color to white
              },
              ".MuiFilledInput-input": {
                color: "#727272", // change text color to grey
              },
            }}
          >
            {/* <TextField
              fullWidth
              size="small"
              label={i18next.t("b2c.header.searchMessage")}
              id="fullWidth"
              variant="filled"
            /> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="body2" sx={{ marginRight: "1rem" }}>
              v{appVersion}
            </Typography>
            <CountryDropdown />
          </Box>

          <Box
            sx={{
              flexGrow: 0,
              marginRight: "1%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <WelcomeName />
            <SignInSignOutButton />
          </Box>
        </Toolbar>
        {/* </Container> */}
      </AppBar>
      <Outlet />
    </>
  );
}
export default ResponsiveAppBar;
