import React from "react";
import {
  selectPatients,
  selectChoosePatient,
  updateObservation,
  Observation,
  removeObservationThunk,
} from "../../store/patientB2BSlice";
import { callApi } from "../../services/MsalApiCall";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TextField from "@mui/material/TextField";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

// from /public, import the JSON file vr_test_filtered.json
import testIdentifier from "../../data/vr_test_filtered.json";
import testAttributes from "../../data/vr_test.json";
import { Tests, TestVR } from "../../types/reference_values";
import { filterReferenceValue } from "../../utils/referenceValue";
import { ValidateInputTestType } from "../../utils/validateInput";
import ValidatedTextField from "./ValidatedTextField";
import { Select, MenuItem } from "@mui/material";
import BRTipoAmostra from "../../types/fhir/BRTipoAmostra.json";
import { useTranslation } from "react-i18next";
import { Specimen } from "../../store/patientB2BSlice";

const testsVR: Tests = testAttributes as Tests;
const specimen = BRTipoAmostra.compose.include[0].concept as any;

export default function ObservationList() {
  const { t } = useTranslation();
  const patients = useAppSelector(selectPatients);
  // selected patient
  const selectedPatient = useAppSelector(selectChoosePatient);
  const patient = patients[selectedPatient];

  const dispatch = useAppDispatch();
  const observations = patients[selectedPatient]?.observations || {};

  const handleUpdateObservation = (
    patientId: string,
    observationId: string,
    updatedObservation: Partial<Observation>,
  ) => {
    if (Object.keys(updatedObservation).length > 0) {
      dispatch(
        updateObservation({
          patientId,
          observationId,
          updatedObservation,
        }),
      );
    }
  };

  const handleDeleteObservation = async (
    patientId: string,
    observationId: string,
    observation: Observation,
  ) => {
    const { accessToken } = await callApi();
    dispatch(
      removeObservationThunk({
        patientId,
        observationId,
        observation,
        accessToken,
      }),
    );
  };

  return (
    <>
      {!selectedPatient ? (
        <Typography variant="h6" style={{ marginTop: "10px" }}>
          {t("b2b.observationList.noPatients")}
        </Typography>
      ) : Object.keys(observations).length === 0 ? (
        <Typography variant="h6" style={{ marginTop: "10px" }}>
          {t("b2b.observationList.noObservations")}
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="observation list table" size="medium">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>{t("b2b.observationList.name")}</TableCell>
                <TableCell>{t("b2b.observationList.result")}</TableCell>
                <TableCell>{t("b2b.observationList.method")}</TableCell>
                <TableCell>{t("b2b.observationList.unit")}</TableCell>
                <TableCell>{t("b2b.observationList.specimen")}</TableCell>
                <TableCell>{t("b2b.addObservation.timeCollection")}</TableCell>
                <TableCell>{t("b2b.addObservation.timeResult")}</TableCell>
                <TableCell>{t("b2b.observationList.action")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(Object.entries(observations) as [string, Observation][]).map(
                ([observationId, observation]) => {
                  const test = testIdentifier.filter(
                    (test: any) => test.LOINC_ID === observationId,
                  );

                  const testMetadata = testsVR[observationId] as TestVR[];
                  const birthDate = new Date(patient.birthDate);
                  const today = new Date();
                  const days = Math.floor(
                    (today.getTime() - birthDate.getTime()) /
                      (1000 * 3600 * 24),
                  );

                  // filter testMetadata by SEX
                  const filteredTestVR: ValidateInputTestType | null =
                    filterReferenceValue(testMetadata, days, patient.sex);

                  // infer type, if no testMetadata, default to string
                  const type = filteredTestVR
                    ? filteredTestVR.type
                    : testMetadata[0]?.TYPE || "string"; //TODO not sure default string is the best option

                  return (
                    <TableRow key={selectedPatient + observationId}>
                      <TableCell component="th" scope="row">
                        {test[0].INTERNAL_ID}
                      </TableCell>
                      <TableCell
                        style={{ maxWidth: "250px", fontSize: "0.7rem" }}
                      >
                        {test ? test[0].TEST : "Unknown Test"}
                      </TableCell>
                      <TableCell>
                        <ValidatedTextField
                          defaultValue={observation.result}
                          filteredTestVR={filteredTestVR}
                          type={type}
                          handleResultChange={(newValue) => {
                            handleUpdateObservation(
                              selectedPatient,
                              observationId,
                              {
                                result: newValue,
                              },
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell
                        style={{ maxWidth: "180px", fontSize: "0.6rem" }}
                      >
                        {observation.method}
                      </TableCell>
                      <TableCell>
                        <Select value={observation.unit}>
                          <MenuItem
                            key={observationId}
                            value={observation.unit}
                          >
                            {observation.unit}
                          </MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Select
                          value={observation.specimen.code}
                          onChange={(event) => {
                            const selectedSpecimen = specimen.filter(
                              (spec: any) => spec.code === event.target.value,
                            );
                            handleUpdateObservation(
                              selectedPatient,
                              observationId,
                              {
                                specimen: {
                                  fhirId: selectedSpecimen[0].fhirId,
                                  code: event.target.value,
                                  display: selectedSpecimen[0].display,
                                } as Specimen,
                              },
                            );
                          }}
                        >
                          {specimen.map((concept: any) => {
                            return (
                              <MenuItem key={concept.code} value={concept.code}>
                                {concept.display}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </TableCell>
                      <TableCell>
                        <TextField
                          id="effectiveDateTime"
                          type="datetime-local"
                          InputLabelProps={{ shrink: true }}
                          value={observation.effectiveTime}
                          InputProps={{ style: { fontSize: "0.7rem" } }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            handleUpdateObservation(
                              selectedPatient,
                              observationId,
                              {
                                effectiveTime: e.target.value,
                              },
                            );
                          }}
                        />
                      </TableCell>

                      <TableCell>
                        <TextField
                          id="testIssuedTime"
                          type="datetime-local"
                          InputLabelProps={{ shrink: true }}
                          value={observation.issuedTime}
                          InputProps={{ style: { fontSize: "0.7rem" } }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            handleUpdateObservation(
                              selectedPatient,
                              observationId,
                              {
                                issuedTime: e.target.value,
                              },
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          color="secondary"
                          onClick={() =>
                            handleDeleteObservation(
                              selectedPatient,
                              observationId,
                              observation,
                            )
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                },
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
