import React from "react";
import { useTranslation } from "react-i18next";
import CustomCards, { CardElement } from "../../components/shared/CustomCards";

function Overview() {
  const { t } = useTranslation();
  const elements: CardElement[] = [
    {
      title: t("overview.dashboard.title"),
      description: t("overview.dashboard.description"),
      uri: "/dashboard",
    },
    // {
    //   title: t("overview.resultsHistory.title"),
    //   description: t("overview.resultsHistory.description"),
    //   uri: "/overview/myhistory",
    // },
    // {
    //   title: t("overview.loginSecurity.title"),
    //   description: t("overview.loginSecurity.description"),
    //   uri: "/overview/mysettings",
    // },
    {
      title: t("overview.customerService.title"),
      description: t("overview.customerService.description"),
      uri: "/overview/customerservice",
    },
    {
      title: t("overview.referralPage.title"),
      description: t("overview.referralPage.description"),
      uri: "/referral",
    },
  ];

  return <CustomCards elements={elements} />;
}

export default Overview;
