import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store"; // Adjust the import path as necessary
import { checkEmail } from "../utils/checkEmail";

// interface
interface SettingsB2B {
  isEmailValid: boolean;
  isEditingPatient: boolean;
  status: "idle" | "loading" | "failed";
}

const initialState: SettingsB2B = {
  isEmailValid: false,
  isEditingPatient: false,
  status: "idle",
};

export const settingsB2BSlice = createSlice({
  name: "settingsB2C",
  initialState,
  reducers: {
    checkValidEmail: (state, action: PayloadAction<string>) => {
      state.isEmailValid = checkEmail(action.payload);
    },
    updateIsEmailValid: (state, action: PayloadAction<boolean>) => {
      state.isEmailValid = action.payload;
    },
    updateIsEditingPatient: (state, action: PayloadAction<boolean>) => {
      state.isEditingPatient = action.payload;
    },
    updateStatus: (
      state,
      action: PayloadAction<"idle" | "loading" | "failed">,
    ) => {
      console.log("updateStatus", action.payload);
      state.status = action.payload;
    },
  },
});

export const {
  checkValidEmail,
  updateIsEmailValid,
  updateIsEditingPatient,
  updateStatus,
} = settingsB2BSlice.actions;

export const selectSettingsB2B = (state: RootState) => state.settingsB2B;
