import { customAlphabet } from "nanoid";

// Define the allowed characters for FHIR ID
const allowedChars =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.";

// Create a custom nanoid generator with the allowed characters and a length limit of 50
const nanoid = customAlphabet(allowedChars, 30);

export const createFhirId = (): string => {
  return nanoid();
};
