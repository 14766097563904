import React, { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import {
  selectPatients,
  updateObservation,
} from "../../../store/patientB2BSlice";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import tests from "../../../data/vr_test_filtered.json";
import {
  handleObservationRequest,
  handleEncounterRequest,
} from "../../../services/fhir"; // Import the API service
import {
  Observation,
  resetPatientsState,
  Encounter,
} from "../../../store/patientB2BSlice";
import { clearPatientCache } from "../../../services/api";
import { callApi } from "../../../services/MsalApiCall";
import { useTranslation } from "react-i18next";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CustomDialog from "../../../components/shared/Dialog";

export default function SubmitObservation() {
  const { t } = useTranslation();
  const patients = useAppSelector(selectPatients);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveToDatabase = async () => {
    // check that all patients have observations
    const allPatientsHaveObservations = Object.values(patients).every(
      (patient: any) => Object.keys(patient.observations).length > 0,
    );
    const { accessToken } = await callApi();

    // check that all observations within each patient have a result
    const allObservationsHaveResults = Object.values(patients).every(
      (patient: any) =>
        Object.values(patient.observations).every(
          (observation: any) => observation.result !== undefined,
        ),
    );

    // alert messages if conditions are not met
    if (!allPatientsHaveObservations) {
      alert(t("b2b.submitObservation.allPatientsMustHaveObservations"));
    } else if (!allObservationsHaveResults) {
      alert(t("b2b.submitObservation.allObservationsMustHaveResults"));
    } else {
      Object.entries(patients).map(
        async ([patientId, patient]: [string, any]) => {
          const responseEncounter = await handleEncounterRequest(
            patient.encounter as Encounter,
            patient.encounter.isNew ? "POST" : "PUT",
            accessToken,
          );

          // Step 1: Create all observations without hasMember references
          await Promise.all(
            Object.entries(
              patient.observations as Record<string, Observation>,
            ).map(async ([loincId, observation]) => {
              // Update the observation status to pending
              dispatch(
                updateObservation({
                  patientId,
                  observationId: loincId,
                  updatedObservation: {
                    status: "loading",
                  },
                }),
              );

              // Create the observation without hasMember references
              await handleObservationRequest(
                "PUT", // Use PUT to create new observations with pre-defined fhirId
                accessToken,
                observation as Observation,
                loincId,
                patient.fhirId,
                responseEncounter.id,
              )
                .then(() => {
                  // update the observation status to success
                  dispatch(
                    updateObservation({
                      patientId,
                      observationId: loincId,
                      updatedObservation: {
                        status: "success",
                      },
                    }),
                  );
                })
                .catch((error) => {
                  dispatch(
                    updateObservation({
                      patientId,
                      observationId: loincId,
                      updatedObservation: {
                        status: "error",
                        error: JSON.stringify(error),
                      },
                    }),
                  );
                });
            }),
          );

          // Step 2: Update observations to include hasMember references
          await Promise.all(
            Object.entries(
              patient.observations as Record<string, Observation>,
            ).map(async ([loincId, observation]) => {
              // Collect FHIR IDs for observations matching the group
              let hasMember: string[] = [];
              if (observation.group) {
                hasMember = Object.values(
                  patient.observations as Record<string, Observation>,
                )
                  .filter((obs) => obs.group === observation.group)
                  .filter((obs) => obs.fhirId !== observation.fhirId)
                  .map((obs) => `Observation/${obs.fhirId}`);
              }

              // Update the observation to include hasMember references
              if (hasMember.length > 0) {
                await handleObservationRequest(
                  "PUT", // Use PUT to update existing observations
                  accessToken,
                  observation as Observation,
                  loincId,
                  patient.fhirId,
                  responseEncounter.id,
                  hasMember,
                )
                  .then(() => {
                    // update the observation status to success
                    dispatch(
                      updateObservation({
                        patientId,
                        observationId: loincId,
                        updatedObservation: {
                          status: "success",
                        },
                      }),
                    );
                  })
                  .catch((error) => {
                    dispatch(
                      updateObservation({
                        patientId,
                        observationId: loincId,
                        updatedObservation: {
                          status: "error",
                          error: JSON.stringify(error),
                        },
                      }),
                    );
                  });
              }
            }),
          );
        },
      );

      const patientEmails = Object.values(patients)
        .map((patient: any) => patient.email)
        .join(",");

      await clearPatientCache(patientEmails, accessToken);
    }
  };

  const handleConfirmClearAll = () => {
    dispatch(resetPatientsState());
    navigate("/");
    handleClose();
  };

  const handleClearAll = () => {
    // Open the dialog to confirm clearing all patients
    handleClickOpen();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2} // Add spacing between items
          style={{ minHeight: "40vh" }}
        >
          <Grid item xs={8}>
            <TableContainer component={Paper}>
              <Table aria-label="patient observations table">
                <TableHead>
                  <TableRow>
                    <TableCell>Visit</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>{t("b2b.submitObservation.testName")}</TableCell>
                    <TableCell>
                      {t("b2b.submitObservation.testResult")}
                    </TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(patients).flatMap((patient: any) => {
                    const observations = Object.entries(
                      patient.observations || {},
                    ) as [string, Observation][];
                    if (observations.length === 0) {
                      return (
                        <TableRow key={patient.id}>
                          <TableCell>{patient.email}</TableCell>
                          <TableCell colSpan={2} style={{ color: "red" }}>
                            {t("b2b.submitObservation.noObservations")}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      );
                    } else {
                      return observations.map(
                        ([key, observation]: [string, Observation]) => {
                          const test = tests.filter(
                            (test: any) => test.LOINC_ID === key,
                          )[0];
                          return (
                            <TableRow key={`${patient.id}-${key}`}>
                              <TableCell>
                                {patient.encounter.encounterId}
                              </TableCell>
                              <TableCell>{patient.email}</TableCell>
                              <TableCell>{test ? test.TEST : ""}</TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  color:
                                    observation.result === undefined
                                      ? "red"
                                      : "black",
                                }}
                              >
                                {observation.result === undefined
                                  ? t("b2b.submitObservation.noResult")
                                  : observation.result}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {observation.status === "loading" && (
                                  <CircularProgress size={20} />
                                )}
                                {observation.status === "success" && (
                                  <CheckIcon style={{ color: "green" }} />
                                )}
                                {observation.status === "error" && (
                                  <CloseIcon
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => alert(observation.error)}
                                  />
                                )}
                                {observation.status === "pending" && (
                                  <HourglassEmptyIcon />
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        },
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid item xs={12} style={{ margin: "1rem", textAlign: "right" }}>
              <Button
                variant="contained"
                onClick={() => handleSaveToDatabase()}
                disabled={Object.keys(patients).length === 0}
              >
                {t("b2b.submitObservation.saveToDatabase")}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 1,
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000, // Ensure the buttons are above other content
            pointerEvents: "none", // Allow clicks to pass through the Box
          }}
        >
          <Button
            variant="contained"
            onClick={() => navigate("/addobservation")}
            sx={{ pointerEvents: "auto" }} // Enable clicks on the button
          >
            {t("b2b.submitObservation.createOrEditObservation")}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleClearAll()}
            disabled={Object.keys(patients).length === 0}
            sx={{ pointerEvents: "auto" }} // Enable clicks on the button
          >
            {t("b2b.submitObservation.clearAll")}
          </Button>
        </Box>
      </Grid>
      <CustomDialog
        open={open}
        title={t("b2b.submitObservation.confirmClearAll")}
        description={t("b2b.submitObservation.confirmClearAll")}
        cancel={t("cancel")}
        confirm={t("confirm")}
        onConfirm={handleConfirmClearAll}
        onCancel={handleClose}
      />
    </Grid>
  );
}
