export const convertAgeSpecToDays = (
  ageSpec: string,
): { operator: string | null; days: number | null } => {
  if (ageSpec === "NB") return { operator: null, days: 0 };
  const match = ageSpec.match(/^([<>]=?)?(\d+)([DMY])$/);
  if (!match) {
    const numberFloat = parseFloat(ageSpec);
    if (!isNaN(numberFloat)) {
      return { operator: null, days: numberFloat };
    }
    return { operator: null, days: null };
  }

  const [, operator, num, unit] = match;
  const number = parseFloat(num);

  // Convert the number to days based on the unit
  let days;
  switch (unit) {
    case "D":
      days = number;
      break;
    case "M":
      days = number * 30; // Approximate conversion: 1 month = 30 days
      break;
    case "Y":
      days = number * 365; // Approximate conversion: 1 year = 365 days
      break;
    default:
      days = number;
  }

  return { operator, days };
};
