import { Typography, Box, Button, ButtonGroup } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import React, { useEffect, useState } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import ResultsOverview from "./ResultsOverview";
import ResultsSingle from "./ResultsSingle";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  selectPatientDataB2C,
  selectStatus,
  selectStatisticsStatus,
  selectPatientSettings,
  selectErrorMessage,
} from "../../store/rawDataB2C";
import setTransformedDataThunk from "../../store/thunks/transformB2CDataThunk";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import { useNavigate } from "react-router-dom";

const drawerWidth = 100; // should be 250

interface Props {
  /**
   * You won't need it on your project.
   */
  window?: () => Window;
  children?: React.ReactElement<unknown>;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children ?? <div />}
    </Slide>
  );
}

// const openedMixin = (theme: Theme): CSSObject => ({
//   width: drawerWidth,
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.enteringScreen,
//   }),
//   overflowX: "hidden",
// });

// const closedMixin = (theme: Theme): CSSObject => ({
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   overflowX: "hidden",
//   width: `calc(${theme.spacing(7)} + 1px)`,
//   [theme.breakpoints.up("sm")]: {
//     width: `calc(${theme.spacing(9)} + 1px)`,
//   },
// });

// const Drawer = styled(MuiDrawer, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   width: drawerWidth,
//   flexShrink: 0,
//   whiteSpace: "nowrap",
//   boxSizing: "border-box",
//   ...(open && {
//     ...openedMixin(theme),
//     "& .MuiDrawer-paper": {
//       ...openedMixin(theme),
//       marginTop: "120px", // Add this line. Adjust the value as per your AppBar's height
//     },
//   }),
//   ...(!open && {
//     ...closedMixin(theme),
//     "& .MuiDrawer-paper": {
//       ...closedMixin(theme),
//       marginTop: "120px", // Add this line. Adjust the value as per your AppBar's height
//     },
//   }),
// }));

export default function Dashboard(props: Props) {
  const { t } = useTranslation();
  // const filters = useAppSelector(selectPatientFilters);
  const patientSettings = useAppSelector(selectPatientSettings);
  const patientData = useAppSelector(selectPatientDataB2C);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Step 1: Call useAppSelector for both selectors
  const status = useAppSelector(selectStatus);
  const errorMessage = useAppSelector(selectErrorMessage);
  const statisticsStatus = useAppSelector(selectStatisticsStatus);

  // Step 2: Use the results to determine isLoading
  const isLoading =
    status !== "idle" ||
    statisticsStatus !== "idle" ||
    Object.keys(patientData.metadataClean).length === 0;

  const [currentPage, setCurrentPage] = useState(
    "b2c.dashboard.globalOverview",
  );

  const handlePageChange = (page: string) => {
    setCurrentPage(page);
  };

  const styles = {
    drawer: {
      width: drawerWidth,
    },
    content: {
      marginLeft: drawerWidth - 100,
    },
  };

  // dispatch setTransformedDataThunk at the beginning
  useEffect(() => {
    if (
      patientData &&
      Object.keys(patientData.metadataClean).length > 0 &&
      patientSettings.filter
    ) {
      dispatch(setTransformedDataThunk(patientData, patientSettings.filter));
    }
  }, [patientData, patientSettings.filter, dispatch]);

  const handleRedirect = () => {
    navigate("/referral");
  };

  // const handleFilterChange = (event: SelectChangeEvent<string>) => {
  //   setFilter(event.target.value);
  //   dispatch(setFilter(event.target.value));
  // };

  return (
    <Box sx={{ display: "flex" }}>
      <HideOnScroll {...props}>
        <AppBar
          component="nav"
          // position="fixed"
          elevation={0}
          style={{ top: 64, backgroundColor: "#f0f0f0" }}
        >
          <Toolbar style={{ minHeight: "24px", margin: "0px" }}>
            <Box sx={{ display: "flex" }}>
              <ButtonGroup variant="text">
                {[
                  "b2c.dashboard.globalOverview",
                  // "b2c.dashboard.singleTests",
                ].map((text, index) => (
                  <Button
                    startIcon={
                      index === 0 ? <ShowChartIcon /> : <DashboardIcon />
                    }
                    onClick={() => handlePageChange(text)}
                    sx={{
                      borderBottom:
                        currentPage === text
                          ? "4px solid #1E3296 !important"
                          : "none",
                      marginRight: "15px",
                      borderRight: "none !important",
                      fontSize: {
                        xs: "0.7rem", // smaller text for extra small screens
                        sm: "0.875rem", // default text size for small screens
                        md: "1rem", // default text size for medium screens and up
                      },
                    }}
                    key={index}
                  >
                    {t(text)}
                  </Button>
                ))}
              </ButtonGroup>
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      {/* <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Divider />
        <Box ml={2}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              {t("b2c.dashboard.sortBy")}
            </FormLabel>
            <RadioGroup
              aria-label="sort-by"
              name="sort-by"
              value={patientSettings.filter}
              onChange={handleFilterChange}
            >
              {filters.map((filterKey: string) => (
                <FormControlLabel
                  value={filterKey}
                  key={filterKey}
                  control={<Radio />}
                  label={
                    <Typography variant="body2">
                      {filterKey === "Reference values"
                        ? t("b2c.dashboard.referenceValues")
                        : t("b2c.dashboard.alphabeticalOrder")}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
        <Divider />
      </Drawer> */}
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 5, marginTop: "10px", overflow: "auto" }}
        style={styles.content}
      >
        {isLoading && status != "failed" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
              flexDirection: "column",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
            <Typography variant="h3" component="div" sx={{ color: "#1E3296" }}>
              Loading...
            </Typography>
          </Box>
        )}
        {!isLoading && status != "failed" && (
          <>
            {currentPage === "b2c.dashboard.singleTests" && <ResultsSingle />}
            {currentPage === "b2c.dashboard.globalOverview" && (
              <ResultsOverview />
            )}
          </>
        )}
        {status === "failed" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
              flexDirection: "column",
              margin: "0 auto",
              maxWidth: "800px",
            }}
          >
            {errorMessage === "404" ? (
              <>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ color: "#1E3296", textAlign: "center", px: 2 }}
                >
                  {t("b2c.dashboard.redirectExplanation")}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleRedirect}
                  sx={{
                    marginTop: "20px",
                    alignSelf: "center",
                    maxWidth: "90vw",
                  }}
                >
                  {t("b2c.dashboard.goToReferral")}
                </Button>
              </>
            ) : (
              <Typography
                variant="h5"
                component="div"
                sx={{ color: "#1E3296" }}
              >
                {t("b2c.tryAgain")}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
