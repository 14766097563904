import { convertNumberToOperatorAndNumber } from "./convertNumberToOperatorAndNumber";

export type ValidateInputTestType = {
  type: string;
  minNumeric: string | null;
  maxNumeric: string | null;
  textNegative: string | null;
  textPositive: string | null;
};

export default function validateInput(
  value: string,
  type: string,
  testInput: ValidateInputTestType | null,
): boolean[] {
  let isValid = false;
  let isOutOfRange = false;

  // transform testInput to lower case and "numerico" to "numeric"
  type = type.toLowerCase();

  let numberString = value;

  // if result is string, return true if value does not match textNegative
  if (type === "string" && testInput) {
    // check that value is in textNegative or textPositive
    isValid =
      value === testInput.textPositive || value === testInput.textNegative;
    isOutOfRange = value !== testInput.textNegative;

    // early return if value is not equal to textNegative
    return [isValid, isOutOfRange];
  }

  if (type === "numerico") {
    type = "numeric";
  }

  if (type === "numeric" || type === "qualitative") {
    // transformation comma to dot only necessary for numerical results in  (FHIR does not accept ,)
    numberString = numberString.replace(/,/g, ".");

    const convertedNumberValue = convertNumberToOperatorAndNumber(numberString);
    // check that operator is null or one of < > <= >=
    const isValidOperator =
      convertedNumberValue.operator === null ||
      ["<", ">", "<=", ">="].includes(convertedNumberValue.operator);

    if (isValidOperator && convertedNumberValue.number) {
      // add or remove a small number to number to correct for operator
      if (convertedNumberValue.operator === "<") {
        convertedNumberValue.number -= 0.000000001;
      }
      if (convertedNumberValue.operator === ">") {
        convertedNumberValue.number += 0.000000001;
      }
    }

    // if testInput is not null, check if value is within range
    // Check range only if it's a valid number
    if (testInput && isValidOperator && convertedNumberValue.number) {
      isValid = true;
      const { operator: operatorVRMin, number: referenceValueMin } =
        convertNumberToOperatorAndNumber(testInput.minNumeric);
      const { operator: operatorVRMax, number: referenceValueMax } =
        convertNumberToOperatorAndNumber(testInput.maxNumeric);

      // Set default operators if they are null
      const finalOperatorMin = operatorVRMin === null ? "<" : operatorVRMin;
      const finalOperatorMax = operatorVRMax === null ? ">" : operatorVRMax;

      if (referenceValueMin !== null) {
        if (finalOperatorMin === "<" || finalOperatorMin === ">") {
          if (convertedNumberValue.number < referenceValueMin) {
            isOutOfRange = true;
          }
        }

        if (finalOperatorMin === "<=" || finalOperatorMin === ">=") {
          if (convertedNumberValue.number <= referenceValueMin) {
            isOutOfRange = true;
          }
        }
      }
      if (referenceValueMax !== null) {
        if (finalOperatorMax === ">" || finalOperatorMin === "<") {
          if (convertedNumberValue.number > referenceValueMax) {
            isOutOfRange = true;
          }
        }

        if (finalOperatorMax === ">=" || finalOperatorMin === "<=") {
          if (convertedNumberValue.number >= referenceValueMax) {
            isOutOfRange = true;
          }
        }
      }
    }
  }

  // Add the rest of your validation logic here

  return [isValid, isOutOfRange];
}
