import { TestVR } from "../types/reference_values";
import { convertAgeSpecToDays } from "./convertAgeSpecToDays";
import { ValidateInputTestType } from "./validateInput";

// Utility function to filter test metadata
type Gender = "male" | "female" | "";

export interface SplitResult {
  operator: string | null;
  number: number | null;
}

export function splitOperatorNumericInput(input: string | null): SplitResult {
  const operatorRegex = /^(>=|<=|>|<|==|!=)/;
  if (input === null) {
    return { operator: null, number: null };
  }
  const match = input.match(operatorRegex);

  if (match) {
    const operator = match[0];
    const number = parseFloat(input.slice(operator.length));
    return { operator, number };
  } else {
    return { operator: null, number: parseFloat(input) };
  }
}

export const filterReferenceValue = (
  testMetadata: TestVR[],
  days: number,
  sex: Gender,
): ValidateInputTestType | null => {
  let filteredMetadata = testMetadata.filter((test) => {
    let testMinDays = null;
    let testMaxDays = null;
    let testOperatorMin = null;
    let testOperatorMax = null;

    // if test.status is null or recommended or normal, continue, otherwise return false
    if (
      test.STATUS !== null &&
      test.STATUS !== "recommended" &&
      test.STATUS !== "normal"
    ) {
      return false;
    }

    // filter test matching sex
    if (test.SEX !== sex && test.SEX !== "") {
      return false;
    }

    if (test.AGE_MIN === "" && test.AGE_MAX === "") {
      return true;
    }

    if (test.AGE_MIN && test.AGE_MIN !== "") {
      const ageSpecResult = convertAgeSpecToDays(test.AGE_MIN);
      testOperatorMin = ageSpecResult.operator;
      testMinDays = ageSpecResult.days;
    }

    if (test.AGE_MAX && test.AGE_MAX !== "") {
      const ageSpecResult = convertAgeSpecToDays(test.AGE_MAX);
      testOperatorMax = ageSpecResult.operator;
      testMaxDays = ageSpecResult.days;
    }

    // always to true since we are filtering by age, so if max if null, it must always be true
    let aboveMin = true;
    let belowMax = true;

    // Check min condition
    // we should never have < in min and > in max, so we can assume that if we have < in min, it should be < in max
    if (testMinDays !== null) {
      if (testOperatorMin === ">=") {
        aboveMin = days >= testMinDays;
      } else if (testOperatorMin === ">") {
        aboveMin = days > testMinDays;
      } else if (testOperatorMin === "<=") {
        aboveMin = days >= testMinDays;
      } else if (testOperatorMin === "<") {
        aboveMin = days > testMinDays;
      } else {
        // for null operator, default to >=
        aboveMin = days >= testMinDays;
      }
    }

    // Check max condition
    if (testMaxDays !== null) {
      if (testOperatorMax === "<=") {
        belowMax = days <= testMaxDays;
      } else if (testOperatorMax === "<") {
        belowMax = days < testMaxDays;
      } else if (testOperatorMax === ">=") {
        belowMax = days <= testMaxDays;
      } else if (testOperatorMax === ">") {
        belowMax = days < testMaxDays;
      } else {
        // for null operator, default to <=
        belowMax = days <= testMaxDays;
      }
    }

    return aboveMin && belowMax;
  });

  // if filteredMetadata is empty, return null
  if (filteredMetadata.length === 0) {
    return null;
  }

  // if both normal and recommended are present in TYPE, keep normal and remove recommended
  const hasNormal = filteredMetadata.some((item) => item.STATUS === "normal");
  const hasRecommended = filteredMetadata.some(
    (item) => item.STATUS === "recommended",
  );

  if (hasNormal && hasRecommended) {
    filteredMetadata = filteredMetadata.filter(
      (item) => item.STATUS === "normal",
    );
  }

  return {
    type: filteredMetadata[0]?.TYPE as string,
    minNumeric: filteredMetadata[0]?.VR_MIN as string,
    maxNumeric: filteredMetadata[0]?.VR_MAX as string,
    textNegative: filteredMetadata[0]?.VR_TEXT_NEGATIVE as string,
    textPositive: filteredMetadata[0]?.VR_TEXT_POSITIVE as string,
  };
};
