import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import React from "react";
import annotationPlugin from "chartjs-plugin-annotation";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { ChartData } from "chart.js";
import { convertNumberToOperatorAndNumber } from "../../utils/convertNumberToOperatorAndNumber";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin,
);

export const options = (
  title: string,
  chartData: number[],
  ymin: number,
  ymax: number,
  refmin: number | null,
  refmax: number | null,
  unit: string,
  observationUnit: string,
  visitDate: string,
) => {
  type Annotation =
    | {
        type: "box";
        xScaleID: string;
        yScaleID: string;
        yMin: number;
        yMax: number;
        backgroundColor: string;
        borderColor: string;
        borderWidth: number;
      }
    | {
        type: "label";
        xScaleID: string;
        yScaleID: string;
        yMin: number;
        yMax: number;
        align: string;
        backgroundColor: string;
        borderColor: string;
        content: string;
        borderWidth: number;
      }
    | {
        type: "line";
        yMin: number;
        yMax: number;
        borderColor: string;
        borderWidth: number;
      };

  // for fill annotation
  // if refmin is null and refmax is not null, set ymin to o and ymax to refmax
  const yMin = refmin ? refmin : ymin;

  //if refmax is null, and refmin not null, set ymin to refmin and ymax to ymax
  const yMax = refmax ? refmax : ymax;

  // font size
  const smallFontSize = 12;
  const largeFontSize = 20;
  const fontSize = window.innerWidth < 800 ? smallFontSize : largeFontSize;

  // if refmin and refmax are null, fill the whole chart with light blue in rgba, otherwise "rgba(37, 162, 31, 0.15)"
  const backgroundColor =
    refmin || refmax ? "rgba(37, 162, 31, 0.15)" : "rgba(31, 109, 163, 0.15)";

  const fillAnnotation = {
    type: "box" as const,
    xScaleID: "x",
    yScaleID: "y",
    yMin: yMin,
    yMax: yMax,
    backgroundColor: backgroundColor,
    borderColor: "transparent",
    borderWidth: 0,
  };

  const annotations: Annotation[] = [fillAnnotation];

  if (refmin) {
    const minRefAnnotation = {
      type: "line" as const, // assert as "box"
      // xScaleID: "x",
      // yScaleID: "y",
      yMin: refmin,
      yMax: refmin,
      borderColor: "rgba(245, 100, 0, 0.6)",
      borderWidth: 2,
      borderDash: [5, 5],
    };
    const minRefAnnotationBox = {
      type: "box" as const, // assert as "box"
      xScaleID: "x",
      yScaleID: "y",
      yMin: refmin,
      yMax: ymin,
      backgroundColor: "rgba(255, 99, 71, 0.15)",
      borderColor: "transparent",
      borderWidth: 0,
    };
    const minRefText = {
      type: "label" as const,
      xScaleID: "x",
      yScaleID: "y",
      yMin: refmin,
      yMax: refmin < 0 ? refmin * 1.15 : refmin * 0.75,
      align: "center",
      backgroundColor: "transparent",
      borderColor: "transparent",
      content: t("b2c.linePlot.minimumReferenceValue"),
      borderWidth: 1,
      color: "darkred",
    };

    annotations.push(minRefText);
    annotations.push(minRefAnnotationBox);
    annotations.push(minRefAnnotation);
  }

  if (refmax) {
    const maxRefAnnotation = {
      type: "line" as const, // assert as "box"
      // xScaleID: "x",
      // yScaleID: "y",
      yMin: refmax,
      yMax: refmax,
      borderColor: "rgba(245, 100, 0, 0.6)",
      borderWidth: 2,
      borderDash: [5, 5],
    };
    const maxRefAnnotationBox = {
      type: "box" as const, // assert as "box"
      xScaleID: "x",
      yScaleID: "y",
      yMin: refmax,
      yMax: ymax,
      backgroundColor: "rgba(255, 99, 71, 0.15)",
      borderColor: "transparent",
      borderWidth: 0,
    };
    const maxRefText = {
      type: "label" as const,
      xScaleID: "x",
      yScaleID: "y",
      yMin: refmax,
      yMax: refmax * 1.1,
      align: "center",
      backgroundColor: "transparent",
      borderColor: "transparent",
      // add translation
      content: t("b2c.linePlot.maximumReferenceValue"),
      borderWidth: 1,
      color: "darkred",
    };

    annotations.push(maxRefText);
    annotations.push(maxRefAnnotationBox);
    annotations.push(maxRefAnnotation);
  }
  return {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        annotations,
      },
      title: {
        display: true,
        text: title, // Add your chart title here
        font: {
          size: fontSize,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const index = context.dataIndex;
            const originalValue = chartData[index];
            return `${title || undefined}: ${originalValue}`;
          },
        },
      },
    },
    scales: {
      y: {
        min: ymin,
        max: ymax,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text:
            (observationUnit && unit === "Absolute" ? observationUnit : "") +
            (observationUnit && unit !== "Absolute"
              ? "% from reference value"
              : ""),
          // increase font size
          font: {
            size: fontSize,
          },
          ticks: {
            font: {
              size: fontSize, // Set a smaller font size for the x-axis legend
            },
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: visitDate,
          // increase font size
          font: {
            size: fontSize,
          },
        },
        ticks: {
          font: {
            size: fontSize, // Set a smaller font size for the x-axis legend
          },
          maxRotation: 0,
          minRotation: 0,
        },
      },
    },
  };
};

interface CardProps {
  title: string;
  yLabels: Array<string>;
  chartData: any;
  ymin: number;
  ymax: number;
  refmin: number | null;
  refmax: number | null;
  xLabels: string[];
  testName: string;
  unit: string;
  observationUnit: string;
}

const LinePlot: React.FC<CardProps> = ({
  title,
  chartData,
  ymin,
  ymax,
  refmin,
  refmax,
  xLabels,
  testName,
  unit,
  observationUnit,
}) => {
  const { t } = useTranslation();
  // iterate over chartData and get the numbers without operators with convertNumberToOperatorAndNumber
  const chartDataNumeric = chartData.map((value: string) => {
    const { operator, number } = convertNumberToOperatorAndNumber(value);
    // if operator < add -0.0000001
    if (number && operator === "<") {
      return number - 0.00001;
    }
    // if operator > add +0.0000001
    if (number && operator === ">") {
      return number + 0.00001;
    }
    return number;
  });

  const formattedXLabels = xLabels.map((label: string) =>
    new Date(label).toLocaleString("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }),
  ); // TODO when redux is implemented, this should be done in the selector
  const data: ChartData<"line"> = {
    labels: formattedXLabels,
    datasets: [
      {
        label: testName || undefined,
        data: chartDataNumeric,
        fill: false,
        pointBorderColor: "darkblue",
        pointBackgroundColor: chartData
          ? chartDataNumeric.map((value: number) => {
              if (refmax !== null) {
                return value > refmax ? "red" : "darkblue";
              }
              if (refmin !== null) {
                return value < refmin ? "red" : "darkblue";
              }
              return "darkblue";
            })
          : "darkblue",
        borderColor: "lightgrey",
        backgroundColor: "lightblue",
        pointRadius: 4,
      },
    ],
  };

  return (
    <Line
      data={data}
      options={options(
        title,
        chartData,
        ymin,
        ymax,
        refmin,
        refmax,
        unit,
        observationUnit,
        t("b2c.linePlot.visitDate"),
      )}
    />
  );
};

export default LinePlot;
