export const convertNumberToOperatorAndNumber = (
  numericInput: string | null,
): { operator: string | null; number: number | null } => {
  if (!numericInput) return { operator: null, number: null };

  const match = numericInput.match(/^(<=|>=|<|>)?(\d+(\.\d*)?)$/);
  if (!match) {
    const numberFloat = parseFloat(numericInput);
    if (!isNaN(numberFloat)) {
      return { operator: null, number: numberFloat };
    }
    return { operator: null, number: null };
  }
  const [, operator = null, num] = match;
  const numberFloat = parseFloat(num);
  return { operator, number: numberFloat };
};
