export const handleApiResponse = async (response: Response) => {
  const statusCode = response.status;

  if (!response.ok) {
    let errorData;
    try {
      errorData = await response.json();
    } catch (error) {
      errorData = await response.text();
    }
    throw { statusCode, errorData };
  }

  if (statusCode === 204) {
    // No Content
    return { statusCode, data: null };
  }

  const data = await response.json();
  return { statusCode, data };
};
