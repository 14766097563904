import React from "react";
import { useTranslation } from "react-i18next";
import CustomCards, { CardElement } from "../../components/shared/CustomCards";

function Overview() {
  const { t } = useTranslation();
  const elements: CardElement[] = [
    {
      title: t("b2b.overview.searchpatient.title"),
      description: t("b2b.overview.searchpatient.description"),
      uri: "/searchpatient",
    },
    {
      title: t("b2b.overview.createpatient.title"),
      description: t("b2b.overview.createpatient.description"),
      uri: "/createpatient",
    },
    {
      title: t("b2b.overview.addobservation.title"),
      description: t("b2b.overview.addobservation.description"),
      uri: "/addobservation",
    },
  ];
  return <CustomCards elements={elements} />;
}

export default Overview;
