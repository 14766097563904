import type { ReferenceRange } from "../../store/patientB2BSlice";
import { convertAgeSpecToDays } from "../../utils/convertAgeSpecToDays";

export const createFhirReferenceRange = (
  referenceRange: ReferenceRange,
  unit?: string,
): any => {
  // initialize referenceValueResource
  const referenceRangeResource: any = {};

  // add reference values
  if (referenceRange.low || referenceRange.high) {
    if (referenceRange.low) {
      referenceRangeResource.low = {
        value: referenceRange.low, // only accepts decimal
        ...(unit && {
          unit: unit,
          system: "http://unitsofmeasure.org",
          code: unit,
        }),
      };
    }

    if (referenceRange.high) {
      referenceRangeResource.high = {
        value: referenceRange.high, // only accepts decimal
        ...(unit && {
          unit: unit,
          system: "http://unitsofmeasure.org",
          code: unit,
        }),
      };
    }

    const minText = referenceRange.low ? `min: ${referenceRange.low}` : "";

    const maxText = referenceRange.high ? `max: ${referenceRange.high}` : "";

    if (minText || maxText) {
      referenceRangeResource.text = `${minText}${maxText}`;
    }
  } else {
    // if type is text
    referenceRangeResource.referenceRange = [
      {
        text: referenceRange.textNegative,
      },
    ];
  }

  // Add sex to referenceRangeResource if it exists
  if (referenceRange.sex) {
    referenceRangeResource.appliesTo = [
      {
        coding: [
          {
            system: "http://hl7.org/fhir/administrative-gender",
            code: referenceRange.sex,
          },
        ],
      },
    ];
  }

  // Add age min and max to referenceRangeResource if they exist
  if (referenceRange.ageMin || referenceRange.ageMax) {
    referenceRangeResource.age = {};
    if (referenceRange.ageMin) {
      const ageMin = convertAgeSpecToDays(referenceRange.ageMin);

      // if operator is >, add 0.0000000001 to ageMin.days
      // for <= and >=, keep the same
      if (ageMin.days && ageMin.operator === ">") {
        ageMin.days += 0.0000000001;
      }

      if (ageMin.days && ageMin.operator === "<") {
        ageMin.days -= 0.0000000001;
      }

      referenceRangeResource.age.low = {
        value: ageMin.days,
        unit: "d",
        system: "http://unitsofmeasure.org",
        code: "d",
      };
    }
    if (referenceRange.ageMax) {
      const ageMax = convertAgeSpecToDays(referenceRange.ageMax);
      // if operator is <, add 0.0000000001 to ageMax.days
      // for <= and >=, keep the same
      if (ageMax.days && ageMax.operator === "<") {
        ageMax.days -= 0.0000000001;
      }

      if (ageMax.days && ageMax.operator === ">") {
        ageMax.days += 0.0000000001;
      }

      referenceRangeResource.age.high = {
        value: ageMax.days,
        unit: "d",
        system: "http://unitsofmeasure.org",
        code: "d",
      };
    }
  }

  // Add condition to referenceRangeResource if it exists
  // Add meaning to referenceRangeResource.type if it exists
  if (referenceRange.status) {
    referenceRangeResource.type = {
      coding: [
        {
          system: "MASILAB referencerange-meaning", //TODO change to correct system
          code: referenceRange.statusCode,
          display: referenceRange.status,
        },
      ],
    };
  }
  return { ...referenceRangeResource };
};

// convert FHIR reference range to internal reference range
export const convertFhirToReferenceRange = (
  fhirReferenceRange: any,
): ReferenceRange => {
  const referenceRange: ReferenceRange = {};

  // Extract low value
  referenceRange.low = fhirReferenceRange.low?.value ?? null;

  // Extract high value
  referenceRange.high = fhirReferenceRange.high?.value ?? null;

  // Extract textNegative
  referenceRange.textNegative = fhirReferenceRange.text ?? null;

  // Extract sex from appliesTo
  if (Array.isArray(fhirReferenceRange.appliesTo)) {
    for (const appliesTo of fhirReferenceRange.appliesTo) {
      for (const coding of appliesTo.coding || []) {
        if (coding.system === "http://hl7.org/fhir/administrative-gender") {
          referenceRange.sex = coding.code ?? null;
        }
      }
    }
  }

  // Extract ageMin and ageMax
  if (fhirReferenceRange.age) {
    referenceRange.ageMin =
      fhirReferenceRange.age.low?.value?.toString() ?? null;
    referenceRange.ageMax =
      fhirReferenceRange.age.high?.value?.toString() ?? null;
  }

  // Extract status and statusCode from type
  if (fhirReferenceRange.type?.coding?.[0]) {
    referenceRange.status = fhirReferenceRange.type.coding[0].display ?? null;
    referenceRange.statusCode = fhirReferenceRange.type.coding[0].code ?? null;
  }

  return referenceRange;
};
